import React, { useState, useRef, useEffect } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import TeamOne from '../component/team/TeamOne';
import TeamTwo from '../component/team/TeamTwo';
import { FaPlay, FaPause } from 'react-icons/fa'; // Import icons
import TeamSenior from '../component/team/TeamSenior';
import FacultyTeam from '../component/team/FacultyTeam';

const Team = () => {
    // const audioRef = useRef(null);
    // const [isPlaying, setIsPlaying] = useState(false); // Initially paused due to autoplay restrictions
    // const initialVolume = 0.09;


    // const togglePlay = () => {
    //     if (audioRef.current) {
    //         if (isPlaying) {
    //             audioRef.current.pause();
    //         } else {
    //             audioRef.current.play();
    //         }
    //         setIsPlaying(!isPlaying);
    //     }
    // };

    // useEffect(() => {
    //     if (audioRef.current) {
    //         audioRef.current.volume = initialVolume;
    //         // Attempt autoplay, but handle potential errors
    //         const playPromise = audioRef.current.play();

    //         if (playPromise !== undefined) {
    //             playPromise.then(() => {
    //                 setIsPlaying(true); // Set to playing if successful
    //             }).catch(error => {
    //                 console.error("Autoplay failed:", error);
    //                 // Leave isPlaying as false if autoplay fails due to browser restrictions.
    //               });
    //           }
    //     }

    //     return () => {
    //         if (audioRef.current && isPlaying) { // Only pause if it's playing
    //             audioRef.current.pause();
    //         }
    //     };
    // }, []);

    return (
        <>
            <SEO title="Team" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Meet talent & experience"
                    paragraph="Meet the brilliant minds driving innovation and excellence at ISTE. Together, we inspire, create, and lead!"
                    styleClass="thumbnail-2"
                    mainThumb="/team-vector.png"
                />

{/* <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>

                <button onClick={togglePlay} style={{
                    borderRadius: '50%', // Make it a circle
                    padding: '12px',     // Adjust padding as needed
                    border: 'none',       // Remove default button border
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                    color: 'white',       // Icon color
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                 }}>
                    {isPlaying ? <FaPause size={20}/> : <FaPlay size={20} />}
                </button>
            </div> */}

                {/* <audio ref={audioRef} src="/team-intro.mp3" loop /> */}
                <FacultyTeam/>
                <TeamSenior/>
                <TeamOne />
                <TeamTwo />
                {/* <CtaLayoutOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default Team;