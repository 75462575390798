import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';


const CaseStudy = () => {

    return (
        <>
        <SEO title="Meet the Creators" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Meet the Creators."
                paragraph ="The minds behind the development of this site."
                styleClass="thumbnail-2"
                mainThumb="https://i.postimg.cc/L8vV1ydM/dream-2.png"
                />
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <CaseStudyProp />
                    </div>
                </div>
                
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default CaseStudy;