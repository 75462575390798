import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok, FaWhatsapp, FaDiscord, FaLink, FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">

                            <li></li>

                            <li><a href="https://www.linkedin.com/in/istesrmncr/"><FaLinkedin /></a></li>

                            <li><a href="https://chat.whatsapp.com/EDSxetcicd07sYllYEi91m?fbclid=PAY2xjawG-nG1leHRuA2FlbQIxMQABppKuDllriKtBJXkcbqv7APCyQHeyW5TivH37poHJ0TT6k6Y12j3wPt1A8w_aem_UIAZfejjNlCnKQqlyKsGsQ" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a></li>

                            <li><a href="https://www.instagram.com/istesrm/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>

                            <li><a href="https://discord.gg/ZrcJ5Qv" target="_blank" rel="noopener noreferrer"><FaDiscord /></a></li>

                            <li><a href="https://github.com/iste-srmncr" target="_blank" rel="noopener noreferrer"><FaGithub /></a></li>

                            <li><a href="https://linktr.ee/istesrmncr?fbclid=PAZXh0bgNhZW0CMTEAAaamKX8Ky-8Be9fpqQdtCVP6gpZTEj0JcEZKDHv8EOTMYje46YincDw6j3g_aem_wP2dBtSinbij6jkfbF9-cA" target="_blank" rel="noopener noreferrer"><FaLink /></a></li>

                            <li></li>
                        </ul>
                    </div>

                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>Reach out and connect with the innovators shaping tomorrow!!</p>
                                    {/* <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    {/* <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Links</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/Events"}>Events</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/team"}>Team</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/devs"}>Developers</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/contact"}>Privacy Policy</Link></li>
                                                {/* <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by ISTE-SRM.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/devs"}>Made with ❤︎ by ISTE Tech Team</Link></li>
                                    {/* <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;