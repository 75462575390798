import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';



const Contact = () => {
    return (
        <>
            <SEO title="Contact" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Contact"
                page="Contact"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Feel free to ask us</h3>
                                <FormTwo />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>Feel free to contact us directly through our social media channels or visit our  campus.</p>
                                <h4 style={{fontSize:"2rem"}}className="phone-number"><a href="mailto:iste.ncr@srmist.edu.in">iste.ncr@srmist.edu.in</a></h4>
                            </div>
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                            <h4 className="title">Address</h4>
    <p>SRM IST Delhi-NCR Campus Delhi - Meerut Road, Modinagar, Uttar Pradesh</p>

    {/* Google Maps Hyperlink */}
    {/* <h4  className="title">Find Us on Google Maps</h4> */}
    <a 
        href="https://www.google.com/maps/place/SRM+Institute+of+Science+and+Technology+NCR+Campus/@28.799357,77.538654,15z/data=!4m2!3m1!1s0x0:0x39add03eb3926d26?sa=X&ved=1t:2428&ictx=111" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="title"
       style={{fontSize:"2rem",color:"#f9b7c3"}}
    >
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3496.325859993874!2d77.53607371003692!3d28.799361676385214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf51f5aadb701%3A0x39add03eb3926d26!2sSRM%20Institute%20of%20Science%20and%20Technology%20NCR%20Campus!5e0!3m2!1sen!2sin!4v1733401358305!5m2!1sen!2sin" 
                width="90%" 
                height="100%" 
                style={{border: 0,borderRadius:"15px"}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        View on Google Maps
    </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

            

            <FooterOne parentClass="pt--0 pt_lg--0 pt_md--10 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;