import React from 'react';
import { Link } from 'react-router-dom';
import SeniorTeamData from "../../data/team/SeniorTeamData.json";
import { slugify } from '../../utils';

const allData = SeniorTeamData;

const TeamSenior = () => {
    return (
        <div className="section section bg-color-dark ">
        <div className="container">
            <div className="section-heading heading-light">
            {/* <span className="subtitle">THE ISTE TEAM</span> */}
            <h2 className="title mb--50">Senior Team</h2>
            {/* <p>Meet the brilliant minds driving innovation and excellence at ISTE. Together, we inspire, create, and lead!</p> */}
            </div>
            <div className="row" style={{
                        display: 'flex',
                        justifyContent: 'center', 
                        alignItems: 'center',    
                    }}>
                {allData.map((data) => (
                    
                    <div className="col-xl-3 col-sm-6" key={data.id} >
                        <div className="team-grid" >
                            <div className="thumbnail">
                                {/* <Link to={process.env.PUBLIC_URL + `/team ${slugify(data.title)}`}> */}
                                <Link to={process.env.PUBLIC_URL + `/team`}>
                                    <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} />
                                </Link>
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    {/* <Link to={process.env.PUBLIC_URL + `/team-details/${slugify(data.title)}`}> */}
                                        {data.title}
                                    {/* </Link> */}
                                </h4>
                                <span className="designation" dangerouslySetInnerHTML={{__html: data.designation}}></span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
        </ul>
    </div>

    )
}

export default TeamSenior;